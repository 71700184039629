(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/coupon-overview-parts/assets/javascripts/outcome-reduce.js') >= 0) return;  svs.modules.push('/components/tipsen/coupon-overview-parts/assets/javascripts/outcome-reduce.js');
"use strict";

const {
  useSelector
} = ReactRedux;
const {
  useCouponId
} = svs.components.tipsen.couponId;
const {
  useBranding
} = svs.components.tipsen.hooks;
const {
  selectReductionSelectionsForEvent
} = svs.components.tipsen.engine.selectors.couponSelectors;
const {
  selectReduction
} = svs.components.tipsen.engine.selectors.engineSelectors;
const {
  Outcome
} = svs.components.lbUi.outcome;
const {
  constants: engineConstants
} = svs.components.tipsen.engine;
const LabelMap = {
  [engineConstants.ReductionScore.HOME]: 'H',
  [engineConstants.ReductionScore.DRAW]: 'O',
  [engineConstants.ReductionScore.AWAY]: 'B'
};
const CouponOverviewReduce = _ref => {
  let {
    eventNumber = 0,
    className = ''
  } = _ref;
  const couponId = useCouponId();
  const [productBranding] = useBranding();
  const reductionValues = useSelector(state => selectReduction(state));
  const selectedReductions = useSelector(state => selectReductionSelectionsForEvent(state, couponId, eventNumber));
  const classNames = ['pg_tipsen__outcome_row--vertical'];
  if (className) {
    classNames.push(className);
  }
  return React.createElement("div", {
    className: classNames.join(' ')
  }, reductionValues.map(value => {
    const dataTestId = "outcome_reduction_".concat(eventNumber, "_").concat(value);
    return React.createElement(Outcome, {
      branding: productBranding,
      className: "pg_overview_outcome_reduce",
      dataTestId: dataTestId,
      isSelected: selectedReductions.includes(value),
      key: "outcome_reduction".concat(value),
      sign: LabelMap[value]
    });
  }));
};
setGlobal('svs.components.tipsen.couponOverviewParts.CouponOverviewReduce', CouponOverviewReduce);

 })(window);