(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/coupon-overview-parts/assets/javascripts/footers/coupon-footer-fulltraff-rows.js') >= 0) return;  svs.modules.push('/components/tipsen/coupon-overview-parts/assets/javascripts/footers/coupon-footer-fulltraff-rows.js');
"use strict";


const {
  useSelector
} = ReactRedux;
const {
  useCouponId
} = svs.components.tipsen.couponId;
const {
  selectSystemSize,
  selectNumberOfCompletedBoards
} = svs.components.tipsen.engine.selectors.couponSelectors;
const {
  CouponFooterRowsView
} = svs.components.tipsen.couponOverviewParts;
const CouponFooterFulltraffRows = _ref => {
  let {
    price,
    system
  } = _ref;
  const couponId = useCouponId();
  const completedBoards = useSelector(state => selectNumberOfCompletedBoards(state, couponId));
  const selectedSystemSize = useSelector(state => selectSystemSize(state, couponId, 0));
  const goalCountNumRows = completedBoards > 0 ? selectedSystemSize : 0;
  const _price = price ? price : 0;
  return React.createElement(CouponFooterRowsView, {
    hasRowLabel: true,
    numRows: goalCountNumRows,
    price: _price,
    system: system
  });
};
setGlobal('svs.components.tipsen.couponOverviewParts.CouponFooterFulltraffRows', CouponFooterFulltraffRows);

 })(window);