(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/coupon-overview-parts/assets/javascripts/footers/coupon-footer-rows-view.js') >= 0) return;  svs.modules.push('/components/tipsen/coupon-overview-parts/assets/javascripts/footers/coupon-footer-rows-view.js');
"use strict";

const {
  useBranding
} = svs.components.tipsen.hooks;
const CouponFooterRowsView = _ref => {
  let {
    numRows,
    maxRows,
    hasRowLabel,
    price,
    system
  } = _ref;
  const [productBranding] = useBranding();
  const overviewFooterClassNames = ['coupon-overview-footer', 'coupon-overview-footer-rows'];
  productBranding && overviewFooterClassNames.push("coupon-overview-footer-".concat(productBranding));
  return React.createElement("div", {
    className: overviewFooterClassNames.join(' ')
  }, React.createElement("span", {
    className: "f-400 coupon-overview-footer-rowtitle"
  }, "Rader: "), React.createElement("span", {
    className: "f-400 coupon-overview-footer-rows"
  }, React.createElement("span", {
    className: "f-medium"
  }, numRows), maxRows && React.createElement("span", null, ' ', "av", ' ', maxRows), hasRowLabel && React.createElement("span", null, " st")), React.createElement("div", {
    className: "f-400 f-medium coupon-overview-footer-price"
  }, "".concat(price > 0 ? price : '-', " kr")), system && system.length > 0 && React.createElement("div", {
    className: "f-400 coupon-overview-footer-system"
  }, system));
};
setGlobal('svs.components.tipsen.couponOverviewParts.CouponFooterRowsView', CouponFooterRowsView);

 })(window);