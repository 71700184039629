(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/coupon-overview-parts/assets/javascripts/use-overview-footer.js') >= 0) return;  svs.modules.push('/components/tipsen/coupon-overview-parts/assets/javascripts/use-overview-footer.js');
"use strict";

const {
  useContext,
  useMemo
} = React;
const {
  DrawContext
} = svs.components.tipsen.drawProvider;
const {
  productIds
} = svs.utils.products;
const {
  CouponFooterFulltraffRows,
  CouponFooterSystemRows,
  CouponFooterHedges,
  CouponFooterScore
} = svs.components.tipsen.couponOverviewParts;
const {
  SYSTEM_REDUCERA_FRITT
} = svs.components.tipsen.engine.constants.systems;
const getCouponFooter = (productId, systemType) => {
  if (productId === productIds.FULLTRAFF) {
    return CouponFooterFulltraffRows;
  } else if (productId === productIds.BOMBEN) {
    return CouponFooterScore;
  } else if (systemType && systemType === SYSTEM_REDUCERA_FRITT) {
    return CouponFooterSystemRows;
  }
  return CouponFooterHedges;
};
const useOverviewFooter = systemType => {
  const {
    productId
  } = useContext(DrawContext);
  return useMemo(() => getCouponFooter(productId, systemType), [productId, systemType]);
};
setGlobal('svs.components.tipsen.couponOverviewParts', {
  useOverviewFooter
});

 })(window);