(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/coupon-overview-parts/assets/javascripts/footers/coupon-footer-score.js') >= 0) return;  svs.modules.push('/components/tipsen/coupon-overview-parts/assets/javascripts/footers/coupon-footer-score.js');
"use strict";


const {
  useSelector
} = ReactRedux;
const {
  format
} = svs.utils;
const {
  useCurrentDraw
} = svs.components.tipsen.drawProvider.hooks;
const {
  useCouponId
} = svs.components.tipsen.couponId;
const {
  selectSystemTypeForEvent
} = svs.components.tipsen.engine.selectors.couponSelectors;
const RowCount = _ref => {
  let {
    couponId,
    eventNumber
  } = _ref;
  const eventSystemType = useSelector(state => selectSystemTypeForEvent(state, couponId, eventNumber));
  return React.createElement("span", null, eventSystemType);
};
const CouponFooterScore = _ref2 => {
  let {
    price
  } = _ref2;
  const draw = useCurrentDraw();
  const couponId = useCouponId();
  const formatCurrency = format.Currency;
  return React.createElement("div", {
    className: "pg_coupon_overview_footer pg_coupon_overview_footer--score"
  }, React.createElement("span", {
    className: "pg_coupon_overview_footer__system"
  }, React.createElement("div", null, draw.drawEvents.map((event, index, arr) => React.createElement(React.Fragment, {
    key: event.eventNumber
  }, React.createElement(RowCount, {
    couponId: couponId,
    eventNumber: event.eventNumber,
    key: event.eventNumber
  }), index !== arr.length - 1 && ' x ')))), React.createElement("div", {
    className: "f-400 f-medium pg_coupon_overview_footer__price"
  }, "".concat(price > 0 ? formatCurrency(price) : '-', " kr")));
};
setGlobal('svs.components.tipsen.couponOverviewParts.CouponFooterScore', CouponFooterScore);

 })(window);