(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/coupon-overview-parts/assets/javascripts/footers/coupon-footer-system-rows.js') >= 0) return;  svs.modules.push('/components/tipsen/coupon-overview-parts/assets/javascripts/footers/coupon-footer-system-rows.js');
"use strict";


const {
  useSelector
} = ReactRedux;
const {
  useCouponId
} = svs.components.tipsen.couponId;
const {
  selectSystemSize,
  selectReduceCount,
  selectNumberOfCompletedBoards
} = svs.components.tipsen.engine.selectors.couponSelectors;
const {
  CouponFooterRowsView
} = svs.components.tipsen.couponOverviewParts;
const CouponFooterSystemRows = _ref => {
  let {
    isValid = true,
    isReduced = true,
    price,
    system
  } = _ref;
  const couponId = useCouponId();
  const completedBoards = useSelector(state => selectNumberOfCompletedBoards(state, couponId));
  const selectedSystemSize = useSelector(state => selectSystemSize(state, couponId, 0));
  const reducedCount = useSelector(state => selectReduceCount(state, couponId));
  const numRows = isReduced ? reducedCount : completedBoards;
  const systemSize = isReduced ? selectedSystemSize : 0;
  const _price = isValid ? price : 0;
  return React.createElement(CouponFooterRowsView, {
    hasRowLabel: !isReduced,
    maxRows: systemSize,
    numRows: numRows,
    price: _price,
    systemLabel: system
  });
};
setGlobal('svs.components.tipsen.couponOverviewParts.CouponFooterSystemRows', CouponFooterSystemRows);

 })(window);