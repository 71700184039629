(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/coupon-overview-parts/assets/javascripts/footers/coupon-footer-hedges.js') >= 0) return;  svs.modules.push('/components/tipsen/coupon-overview-parts/assets/javascripts/footers/coupon-footer-hedges.js');
"use strict";

const {
  useSelector
} = ReactRedux;
const {
  useCouponId
} = svs.components.tipsen.couponId;
const {
  selectHalfHedges,
  selectFullHedges
} = svs.components.tipsen.engine.selectors.couponSelectors;
const {
  format
} = svs.utils;
const CouponFooterHedges = _ref => {
  let {
    system = '',
    isValid = true,
    price
  } = _ref;
  const couponId = useCouponId();
  const halfCount = useSelector(state => selectHalfHedges(state, couponId, 0));
  const fullCount = useSelector(state => selectFullHedges(state, couponId, 0));
  const _price = isValid ? price : 0;
  return React.createElement("div", {
    className: "coupon-overview-footer coupon-overview-footer-hedges"
  }, React.createElement("div", {
    className: "f-400 coupon-overview-footer-system"
  }, system), React.createElement("div", {
    className: "f-400 coupon-overview-footer-full"
  }, "Hel:", ' ', React.createElement("span", {
    className: "f-medium"
  }, fullCount)), React.createElement("div", {
    className: "f-400 coupon-overview-footer-divider"
  }, "|"), React.createElement("div", {
    className: "f-400 coupon-overview-footer-half"
  }, "Halv:", ' ', React.createElement("span", {
    className: "f-medium"
  }, halfCount)), React.createElement("div", {
    className: "f-400 f-medium coupon-overview-footer-price"
  }, "".concat(_price > 0 ? format.Currency(_price) : '-', " kr")));
};
setGlobal('svs.components.tipsen.couponOverviewParts.CouponFooterHedges', CouponFooterHedges);

 })(window);