(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/coupon-overview-parts/assets/javascripts/coupon-overview-header.js') >= 0) return;  svs.modules.push('/components/tipsen/coupon-overview-parts/assets/javascripts/coupon-overview-header.js');
"use strict";

var _svs$moduleBuilder;

const {
  SYSTEM_REDUCERA_FRITT
} = svs.components.tipsen.engine.constants.systems;
const {
  When,
  If,
  Choose
} = svs.components.lbUtils.reactConditionals;
const {
  match,
  goalCount
} = ((_svs$moduleBuilder = svs.moduleBuilder) === null || _svs$moduleBuilder === void 0 || (_svs$moduleBuilder = _svs$moduleBuilder.data) === null || _svs$moduleBuilder === void 0 || (_svs$moduleBuilder = _svs$moduleBuilder.play) === null || _svs$moduleBuilder === void 0 ? void 0 : _svs$moduleBuilder.couponOverviewHeader) || {};
const {
  useMediaQuery,
  breakpoints,
  BREAKPOINT_MD
} = svs.components.lbUtils.useMediaQuery;
const CouponOverviewHeader = _ref => {
  let {
    branding,
    style = {},
    systemType
  } = _ref;
  const isWideDevice = useMediaQuery(breakpoints.down(BREAKPOINT_MD));
  const overviewHeaderClassNames = ['tipsen-overview-row-header margin-xs-h-2'];
  if (systemType === SYSTEM_REDUCERA_FRITT && !isWideDevice) {
    overviewHeaderClassNames.push('tipsen-border-bottom');
  }
  if (branding && branding.length > 0) {
    overviewHeaderClassNames.push(" tipsen-overview-row-header-".concat(branding));
  }
  const hasCouponOverviewHeader = Boolean(match && goalCount);
  return React.createElement(React.Fragment, null, React.createElement(If, {
    condition: isWideDevice
  }, React.createElement("h3", {
    className: "f-800 f-center overview-coupon-header"
  }, "\xD6versikt")), React.createElement("div", {
    className: overviewHeaderClassNames.join(' ')
  }, React.createElement(Choose, null, React.createElement(When, {
    condition: hasCouponOverviewHeader
  }, React.createElement("div", {
    className: "tipsen-overview-header-match"
  }, match), React.createElement("div", {
    className: "tipsen-overview-header-goalcount"
  }, goalCount)), React.createElement(When, {
    condition: systemType === SYSTEM_REDUCERA_FRITT
  }, React.createElement("div", {
    className: "tipsen-overview-row-marks",
    style: style
  }, React.createElement("span", null, "1"), React.createElement("span", null, "X"), React.createElement("span", null, "2"))))));
};
setGlobal('svs.components.tipsen.couponOverviewParts.CouponOverviewHeader', CouponOverviewHeader);

 })(window);